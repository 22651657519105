@-webkit-keyframes checkmark {
    0% {
        stroke-dashoffset: 100px
    }

    100% {
        stroke-dashoffset: 200px
    }
}

@-ms-keyframes checkmark {
    0% {
        stroke-dashoffset: 100px
    }

    100% {
        stroke-dashoffset: 200px
    }
}

@keyframes checkmark {
    0% {
        stroke-dashoffset: 100px
    }

    100% {
        stroke-dashoffset: 0px
    }
}

@-webkit-keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 480px
   
    }

    100% {
        stroke-dashoffset: 960px;
      
    }
}

@-ms-keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 240px
    }

    100% {
        stroke-dashoffset: 480px
    }
}

@keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 480px 
    }

    100% {
        stroke-dashoffset: 960px
    }
}

@keyframes colored-circle { 
    0% {
        opacity:0
    }

    100% {
        opacity:100
    }
}

.inlinesvg .svg svg {
    display: inline
}


.icon--order-success svg polyline {
    -webkit-animation: checkmark 1s ease-in-out 1s forwards;
    animation: checkmark 1s ease-in-out 1s forwards
}

.icon--order-success svg circle {
    -webkit-animation: checkmark-circle 2s ease-in-out forwards;
    animation: checkmark-circle 2s ease-in-out infinite;
}
.icon--order-success svg circle#colored {
    -webkit-animation: colored-circle 1s ease-in-out 1s forwards;
    animation: colored-circle 1s ease-in-out 1s forwards;
} 

.icon.icon--order-success.svg {
    width: 55px;
    text-align: center;
    height: 55px;
    border-radius: 100%;
    background: #ffff;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
   

}

@keyframes shadow-circle {
    0% {
        box-shadow: 0 0 0 0px  #F0F0F0;
    }

    100% {
        box-shadow: 0 0 0 20px  #F0F0F0;
    }
}
