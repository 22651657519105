@import-normalize;
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Roboto";
  }
}

@font-face {
  font-family: "Roboto";
  src: url("../src/assets/fonts/Roboto-Medium.woff2") format("woff2"),
    url("../src/assets/fonts/Roboto-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../src/assets/fonts/Roboto-Regular.woff2") format("woff2"),
    url("../src/assets/fonts/Roboto-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../src/assets/fonts/Roboto-Bold.woff2") format("woff2"),
    url("../src/assets/fonts/Roboto-Bold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Tondo";
  src: url("../src/assets/fonts/tondo-light-webfont.ttf");
  src: url("../src/assets/fonts/tondo-light-webfont.eot") format("embedded-opentype");
  src: url("../src/assets/fonts/tondo-light-webfont.svg");
  src: url("../src/assets/fonts/tondo-light-webfont.woff");
  font-weight: 100;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Tondo";
  src: url("../src/assets/fonts/tondo-regular-webfont.ttf");
  src: url("../src/assets/fonts/tondo-regular-webfont.eot") format("embedded-opentype");
  src: url("../src/assets/fonts/tondo-regular-webfont.svg");
  src: url("../src/assets/fonts/tondo-regular-webfont.woff");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: "Tondo";
  src: url("../src/assets/fonts/tondo-bold-webfont.ttf");
  src: url("../src/assets/fonts/tondo-bold-webfont.eot") format("embedded-opentype");
  src: url("../src/assets/fonts/tondo-bold-webfont.svg");
  src: url("../src/assets/fonts/tondo-bold-webfont.woff");
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

html,
body {
  overflow-x: hidden;
}

html {
  // background-color: #212121;
}

// #root {
//   background-color: #212121;
// }

body {
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  // background-color: #212121;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  margin: 0;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.internetIcon {
  position: fixed;
  right: 0.2em;
  top: 0.5em;
}

@media all and (display-mode: standalone) {
  .add-to-home-screen {
    display: none;
  }

  .toast-info {
    display: block;
  }
}

@media all and (display-mode: fullscreen) {
  .add-to-home-screen {
    display: none;
  }
}

::-webkit-scrollbar {
  width: 0px;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

.pin-ctn {
  input {
    color: black;
    // width: 22px !important;
    // height: 22px !important;
  }

  input:not([value=""]) {
    background-color: black;
  }
}

.mobile-input-box:focus,
.otpContainer input:focus {
  box-shadow: none;
  outline: none;
}

/* width */
.static-card::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.static-card::-webkit-scrollbar-track {
  background: #e3e3e3;
  border-radius: 10px;
}

/* Handle */
.static-card::-webkit-scrollbar-thumb {
  background: #ff2d55;
  border-radius: 10px;
}

.gsm-bal-card .body {
  min-height: 110px;
}

.static-card {
  max-height: calc(100vh - 110px);
}

@media (max-width: 340px) {
  .static-card {
    max-height: calc(100vh - 120px);
  }
}

input:focus,
input {
  outline: none;
  box-shadow: none;
}

.placeholder-black::placeholder {
  color: #000;
}

.spinner {
  width: 55px;
  height: 55px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #ec1b25;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  display: block;
}

/* this class is use for Buy Bundle Tabs Last Nav  */
.data_bundle .nav-item {
  &:last-child {
    .heading {
      font-size: 18px;
    }
  }
}

/* End */

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (max-height: 640px) {
  .scroll-height {
    max-height: calc(100vh - 200px);
    overflow: auto;
  }

  .mpesa-card-ctn {
    margin-bottom: 140px;
  }

  /* HE-Error Screen CSS */
  .initial_error {
    align-items: start;
    padding-top: 40px;

    .errorIcn {
      max-width: 142px;
      margin-bottom: 16px;
    }
  }
}

@media (max-height: 450px) {
  .phoneNumber_wrap {
    background: linear-gradient(360deg, white 80%, transparent);
    margin: 0 -20px;
    padding: 0 20px;
    overflow: auto;
  }

  .set-question-overflow {
    overflow: auto;
    padding-bottom: 100px;

    &>div {
      max-height: 300px;
    }
  }
}

/* Firefox */
@media (prefers-color-scheme: dark) {
  :root {
    color-scheme: light only;
  }
}

/* Chrome */
@media (forced-colors: active) {
  :root {
    color-scheme: light only;
  }
}

.toast-info {
  // display: none;
}

@media (max-width:360px) {
  .am-pin-ctn {
    top: 30px !important;
  }

}


.layout-container {
  --webkit-background-color: "#FAFAFA";
  background-color: #FAFAFA;
}

.login-container {
  @media (max-height: 667px) {
    padding-top: 20px;
  }
}

.toast-success {
  background: #BEBEBE;
  color: #555555;
  font-size: 14px;
  font-weight: bold;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  border-radius: 50px;
  padding-left: 15px;
  padding-right: 15px;
  height: fit-content;
  padding-top: 12px;
  padding-bottom: 12px;
  min-height: 0px;

  .Toastify__toast-body {
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;

    .Toastify__toast-icon {
      display: none;
    }
  }

  .Toastify__close-button {
    display: none;
  }
}