.loader-container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  cursor: pointer;

  .spinner {
    width: 4em;
    height: 4em;
    border: 0.5em solid rgba(0, 0, 0, 0.1);
    border-left-color: #fa3251;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
}

// #root {
//   background: #212121;
// }

// html,
// body {
//   background-color: #212121;
// }

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

// @media screen and (min-aspect-ratio: 13/9) and (orientation: landscape) and (max-device-width: 1023px) {
//   .block-landscape {
//     display: block;
//     width: 100vw;
//     height: 100vh;
//     z-index: 100;
//   }
// }
@media screen and (min-width: 480px) and (max-width: 1023px) and (orientation: landscape) {
  .block-landscape {
    display: block;
    width: 100vw;
    height: 100vh;
    z-index: 100;
  }
}
@media (max-width:318px) {
  .text-20 {
    font-size: 12px;
  }

}